import React from 'react';
import Chip from '@mui/material/Chip';

const InvoiceStatusRenderer = ({ value }) => {
  let color = 'primary';
  let label = value ? value.charAt(0).toUpperCase() + value.slice(1) : '';
  let classname = '';

  return (
    <div style={{ textAlign: 'center' }}>
      <Chip size='small' color={color} className={classname} label={label} variant="outlined" />
    </div>
  );
}

export default InvoiceStatusRenderer;
